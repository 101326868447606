.track-hover {
  text-align: left;
  color: #000000;
  background: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  z-index: 100;
  font-size: 17px;
  padding: 2px 4px;
  max-width: 100%;
  border-radius: 2px;
  border: 2px solid #deb100;
}

body {
  font-family: "Arial";
}

.tooltippp {
  position: relative;
  display: inline-block;
}

.tooltippp .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltippp .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltippp:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/*.under-cursor {*/
/*  border-bottom: 2px solid #1abbd4 !important;*/
/*}*/

/*line-height: 24px;*/
